import axiosInstance from "../axiosInstance";

export const resendWelcomeEmail = async (user_token, studio_id, params) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.post(
    "students/welcome-email",
    params,
    config
  );
  return res;
};

export const sendGetRequest = async (
  user_token,
  studio_id,
  url,
  customHeaders
) => {
  const config = customHeaders
    ? customHeaders
    : {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${user_token}`,
          "Studio-Id": studio_id,
        },
      };

  const res = await axiosInstance.get(url, config);
  return res;
};

export const sendPostRequest = async (user_token, studio_id, url, params) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.post(url, params, config);
  return res;
};

export const sendPostMultipartRequest = async (
  user_token,
  studio_id,
  URL,
  params
) => {
  const formData = new FormData();

  Object.keys(params).forEach((fieldName) => {
    if (Array.isArray(params[fieldName])) {
      params[fieldName].forEach((item, idx) => {
        if (item instanceof File) {
          formData.append(`${fieldName}[${idx}]`, item);
        } else if (typeof item === "object") {
          Object.keys(item).forEach((key) => {
            formData.append(`${fieldName}[${idx}][${key}]`, item[key]);
          });
        } else {
          formData.append(`${fieldName}[${idx}]`, item);
        }
      });
    } else {
      formData.append(fieldName, params[fieldName]);
    }
  });

  const res = await axiosInstance.post(URL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  });
  return res;
};

export const sendPacthRequest = async (user_token, studio_id, url, params) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.patch(url, params, config);
  return res;
};
