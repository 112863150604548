import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const hours = Array.from({ length: 24 }, (_, i) => i);

const CustomTimePicker = () => {
  return (
    <Select
      placeholder="Select Time"
      size="middle"
      className="max-w-[100px]"
    >
      {hours.map(hour => (
        <Option key={hour} value={hour}>
          {String(hour).padStart(2, '0')}:00
        </Option>
      ))}
    </Select>
  );
};

export default CustomTimePicker;
