import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./register.styles.scss";

import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import Dots from "../../assets/images/dots.svg";
import {
  registerStudent,
  setIsRegisterStudent,
  setStatusDefault,
} from "../../redux/studio/studio.action";
import {
  requestOtpActivate,
  setLoginFromStudio,
  setNewUser,
} from "../../redux/user/user.action";
import { ModalLogin } from "../StudioLogin/modal";
import { getStudioInfo } from "../../services/studios";
import UploadPicture from "../../components/uploadPicture";
import { Form, Input, Select } from "antd";

const RegisterStudent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const email = Form.useWatch("email", form);

  const { login_email, studio_detail } = useSelector((state) => state.user);
  const { isLoading, status, user_studio } = useSelector(
    (state) => state.studio
  );

  const [colorTheme, setColorTheme] = useState("");
  const [logo, setLogo] = useState("");
  const [pictureData, setPictureData] = useState(null);
  const [showModalReactivateStudent, setShowModalReactivateStudent] =
    useState(false);

  const subdomain = window.location.host.split(".")[0];

  useEffect(() => {
    if (status === "success") {
      history.push("/verify");
      dispatch(setStatusDefault());
    }
  }, [status]);

  const handleGetStudioInfo = async () => {
    try {
      const res = await getStudioInfo(subdomain);
      setLogo(res.data.data.studio_logo_link);
      setColorTheme(res.data.data.studio_header_color);
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequestOtpActivate = () => {
    let params = {
      email: email,
      role: "student",
    };

    dispatch(requestOtpActivate(params, subdomain));
    history.push({
      pathname: "/verify",
      state: { isReactivate: true, emailFromRegister: email },
    });
  };

  const handleSubmit = ({
    first_name,
    last_name,
    email,
    phone_number,
    gender,
  }) => {
    let params = {
      first_name,
      last_name,
      email,
      gender,
    };

    if (phone_number) params.phone_number = phone_number;
    if (pictureData) params.profile_picture = pictureData;

    dispatch(registerStudent(params, subdomain));
  };

  useEffect(() => {
    handleGetStudioInfo();

    if (user_studio?.data) {
      dispatch(setNewUser(user_studio?.data));
      dispatch(setLoginFromStudio());
      dispatch(setIsRegisterStudent());
    } else if (user_studio?.is_account_active === 0) {
      setShowModalReactivateStudent(true);
    }
  }, [user_studio]);

  return (
    <div className="h-full">
      <div className="md:flex min-h-screen w-full">
        <div
          className="md:w-3/5"
          style={{
            backgroundColor: colorTheme || "#60369B",
            padding: 30,
          }}
        >
          <img
            src={Dots}
            className="w-56 absolute top-[230px] -left-[100px] z-0 md:top-[75%]"
            alt="dots"
          />
          <img
            src={Dots}
            className="w-56 absolute top-0 right-0 md:right-[40%] z-0 "
            alt="dots"
          />
          <div className="text-white text-title">
            {studio_detail?.studio_name}
          </div>
          <div
            style={{ height: "100%" }}
            className="d-flex flex-column justify-content-center align-items-center flex-wrap text-white"
          >
            <img
              src={studio_detail?.logo_link || logo}
              alt="logo"
              className="logo-style"
            />
            <h4 className="mt-4">
              WELCOME TO {studio_detail?.studio_name.toUpperCase()}
            </h4>
            <div style={{ width: 400, textAlign: "center", color: "#BAC2DB" }}>
              <p>
                One app fit for the entire studios. Making learn fun and
                exciting
              </p>
              <p>Flexible learning inspired by the pandemic</p>
            </div>
          </div>
        </div>

        <div className="flex justify-center md:w-2/5 px-20 items-center">
          <div className="login-content">
            <h1 className=" mt-4 mb-5 text-3xl">Register Now</h1>
            <Form
              form={form}
              onFinish={handleSubmit}
              layout="vertical"
              scrollToFirstError
            >
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your First Name",
                  },
                ]}
              >
                <Input className="h-10" placeholder="Input Your First Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Last Name",
                  },
                ]}
              >
                <Input placeholder="Input Your Last Name" className="h-10" />
              </Form.Item>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email Address",
                  },
                ]}
              >
                <Input placeholder="Input Your Email" className="h-10" />
              </Form.Item>
              <Form.Item label="Phone Number" name="phone_number">
                <Input
                  type="number"
                  placeholder="Input Your Phone Number"
                  className="h-10"
                />
              </Form.Item>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Please input your Gender",
                  },
                ]}
                initialValue="male"
              >
                <Select
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                  ]}
                  placeholder="Select Your Gender"
                  className="h-10"
                />
              </Form.Item>

              <Form.Item>
                <UploadPicture
                  themeColor={colorTheme}
                  fileData={(file) => setPictureData(file)}
                />
              </Form.Item>

              <span className="mt-3 regular-text">
                By clicking `Submit` you are in agreement with the{" "}
                <a
                  target="_blank"
                  href="/general-terms"
                  className="font-semibold no-underline"
                >
                  general terms.
                </a>
              </span>

              <Form.Item>
                <Button
                  buttonType="submit"
                  isLoading={isLoading}
                  type="primary"
                  title="Submit"
                  className="my-3"
                  style={{
                    width: "100%",
                    backgroundColor: colorTheme || "#E26851",
                    borderRadius: 0,
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>

      <Footer />

      <ModalLogin
        show={showModalReactivateStudent}
        title="Welcome Back!"
        label="Continue to reactivate your account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"CONFIRM"}
        handleClose={() => setShowModalReactivateStudent(false)}
        handleSubmit={sendRequestOtpActivate}
      />
    </div>
  );
};

export default RegisterStudent;
