import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../../../components/Button/button.component";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import AtomLoading from "../../../../components/loding-indicator";
import AtomNumberFormat from "../../../../components/number-format";
import { getListStatistic } from "../../../../services/dashboard";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import { ConfigProvider, Table, Empty, Space } from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const ListStatistic = (props) => {
  const { title, startDate, endDate, action_type } = props.location.state;

  const history = useHistory();
  const [cookies] = useCookies(["user"]);

  const [tableHead, setTableHead] = useState([]);
  const [listStatistic, setListStatistic] = useState([]);
  const [active4, setActive4] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState({});

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const handleGetListStatistic = async () => {
    setIsLoading(true);
    try {
      const res = await getListStatistic(
        user_token,
        studio_id,
        startDate,
        endDate,
        action_type,
        active4
      );

      setListStatistic(res.data);
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetListStatistic();
  }, [active4]);

  const columns =
    title === "Classes"
      ? [
          {
            title: "Class Name",
            dataIndex: "class_name",
            key: "class_name",
          },
          {
            title: "Location",
            dataIndex: "location_name",
            key: "location_name",
          },
          {
            title: "Room",
            dataIndex: "room",
            key: "room",
          },
          {
            title: "Credits",
            dataIndex: "credits",
            key: "credits",
          },
          {
            title: "Price",
            dataIndex: "class_price",
            key: "class_price",
            render: (text) => <AtomNumberFormat prefix={"IDR"} value={text} />,
          },
        ]
      : title === "Registrants" || title === "Attendants"
      ? [
          {
            title: "Name",
            dataIndex: "registrant_name",
            key: "registrant_name",
          },
          {
            title: "Registration Date",
            dataIndex: "registration_date",
            key: "registration_date",
          },
          {
            title: "Status",
            dataIndex: "booking_class_status",
            key: "booking_class_status",
          },
          {
            title: "Class Name",
            dataIndex: "name",
            key: "name",
          },
        ]
      : [
          {
            title: "Order Number",
            dataIndex: "order_number",
            key: "order_number",
          },
          {
            title: "Transaction Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Category",
            dataIndex: "category",
            key: "category",
          },
          {
            title: "Payment Type",
            dataIndex: "payment_type",
            key: "payment_type",
          },
          {
            title: "Settlement Date",
            dataIndex: "settlement_date",
            key: "settlement_date",
          },
          {
            title: "Total Amount",
            dataIndex: "total_amount",
            key: "total_amount",
            render: (text) => <AtomNumberFormat prefix={"IDR"} value={text} />,
          },
        ];

  return (
    <>
      <OrganismLayout title={title} maxWidth="56rem" className="p-0">
        <Col>
          <div className="flex justify-between mb-8">
            <Space>
              <ArrowLeftOutlined
                className="text-xl"
                onClick={() => history.goBack()}
              />
              <h2>{title}</h2>
            </Space>
          </div>

          {isLoading ? (
            <AtomLoading />
          ) : (
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg:
                      hexToRGBA(studio_detail?.styles?.header_color, 0.1) ||
                      "#fafafa",
                  },
                },
              }}
            >
              <Table
                columns={columns}
                dataSource={listStatistic.data}
                rowKey="id"
                locale={{ emptyText: <Empty /> }}
                size="large"
                pagination={false}
                scroll={{ x: true }}
              />
            </ConfigProvider>
            // <Table
            //   hover
            //   responsive="sm"
            //   className="mb-0 overflow-hidden px-3 text-center"
            //   style={{ borderRadius: "10px 10px 0px 0px", fontSize: "14px" }}
            // >
            //   <thead>
            //     <tr>
            //       {tableHead?.map((el, idx) => (
            //         <th
            //           className="py-3 fw-bold"
            //           style={{ backgroundColor: "#ededed" }}
            //           key={idx}
            //         >
            //           {el}
            //         </th>
            //       ))}
            //     </tr>
            //   </thead>
            //   {title === "Classes" ? (
            //     <tbody>
            //       {listStatistic.data?.length ? (
            //         listStatistic.data?.map((el, idx) => (
            //           <tr key={idx} className="py-2">
            //             <td>{el?.class_name}</td>

            //             <td>{el?.location}</td>
            //             <td>{el?.room}</td>
            //             <td>{el?.credits}</td>
            //             <td>
            //               <AtomNumberFormat
            //                 prefix="IDR "
            //                 value={el?.class_price}
            //               />
            //             </td>
            //           </tr>
            //         ))
            //       ) : (
            //         <tr>
            //           <td colSpan={7} className="p-5">
            //             <EmptyData />
            //           </td>
            //         </tr>
            //       )}
            //     </tbody>
            //   ) : title === "Transactions" ? (
            //     <tbody>
            //       {listStatistic.data?.length ? (
            //         listStatistic.data?.map((el, idx) => (
            //           <tr key={idx} className="py-2">
            //             <td>{el?.order_number.split("-")[0]}</td>
            //             <td>{el?.name}</td>
            //             <td className="text-capitalize">{el?.category}</td>
            //             <td className="text-capitalize">
            //               {el?.payment_type.replace("_", " ")}
            //             </td>
            //             <td>{el?.settlement_date}</td>
            //             <td>
            //               <AtomNumberFormat
            //                 prefix="IDR "
            //                 value={el?.total_amount}
            //               />
            //             </td>
            //           </tr>
            //         ))
            //       ) : (
            //         <tr>
            //           <td colSpan={6} className="p-5">
            //             <EmptyData />
            //           </td>
            //         </tr>
            //       )}
            //     </tbody>
            //   ) : (
            //     <tbody>
            //       {listStatistic.data?.length ? (
            //         listStatistic.data?.map((el, idx) => (
            //           <tr key={idx} className="py-2">
            //             <td>{el?.registrant_name}</td>
            //             <td>{el?.registration_date}</td>
            //             <td>{el?.booking_class_status}</td>
            //             <td>{el?.name}</td>
            //           </tr>
            //         ))
            //       ) : (
            //         <tr>
            //           <td colSpan={7} className="p-5">
            //             <EmptyData />
            //           </td>
            //         </tr>
            //       )}
            //     </tbody>
            //   )}
            // </Table>
          )}
        </Col>
      </OrganismLayout>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={scheduleOpen?.visible}
        onHide={() => setScheduleOpen({ visible: false })}
      >
        <div className="py-4 px-3 text-center">
          <h2 className="heading1 m-0">{scheduleOpen?.name}</h2>
          <div className="mx-5 p-2 my-4 rounded">
            {scheduleOpen.schedules?.map((el, idx) => (
              <Row key={idx}>
                <Col>
                  <p>{el?.day}</p>
                </Col>
                <Col className="text-decoration-underline">
                  <p>
                    {el?.start_time} - {el?.end_time}
                  </p>
                </Col>
              </Row>
            ))}
          </div>
          <div className="d-flex justify-content-center ">
            <Button
              type="primary"
              title="Close"
              onClick={() => setScheduleOpen({ visible: false })}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListStatistic;
