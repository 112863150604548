import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import calender from "../../../../assets/images/calender.svg";
import AtomLoading from "./../../../../components/loding-indicator";
import { ConfigProvider, Table, Modal, Tag, Select, Space } from "antd";
import "./../students.styles.scss";
import { useCookies } from "react-cookie";
import {
  getListOfferingClasses,
  getMyActivePlan,
  getPublicClasses,
  getPublicStudioLocation,
} from "./../../../../redux/student/student.action";
import "react-calendar/dist/Calendar.css";
import EmptyData from "../../../../components/no-data";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { DateRange } from "react-date-range";
import hexToRGBA from "../../../../helper/hexToRbga";
import Button from "../../../../components/Button/button.component";
import { getStudentPlan } from "../../../../redux/owner/owner.action";
import ModalDetailClass from "./modal-detail-class";
import ModalSuccessBookingClass from "../../../../components/Modal/success-booking-class";
import { getDisbursementSetting } from "../../../../services/payment";
import { sendGetRequest } from "../../../../services/students";
import { getLocation } from "../../../../redux/location/location.action";
import { setMenuActive } from "../../../../redux/user/user.action";
import { CaretDownOutlined } from "@ant-design/icons";
import { capitalize } from "../../../../helper/truncateString";

const typeBoolean = {
  TRUE: true,
  FALSE: false,
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const ClassIndex = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentDate = new Date();
  let last = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);

  const [cookies] = useCookies(["user"]);
  const [isModalOpenDateRange, setIsModalDatOpeRange] = useState(false);
  const [isModalOpenDetailClass, setIsModalOpenDetailClass] = useState(false);
  const [prosessEndroll, setProsessEndroll] = useState(false);
  const [confirmMethod, setConfirmMethod] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentGateway, setPaymentGateway] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [selectedClassDetail, setSelectedClassDetail] = useState({});
  const [state, setState] = useState([
    {
      startDate: currentDate,
      endDate: last,
      key: "selection",
    },
  ]);
  const [classId, setClassId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [locationFilterId, setLocationFilterId] = useState(null);
  const [classCategoryId, setClassCategoryId] = useState(null);
  const [classCategories, setClassCatogories] = useState([]);
  const [studioClasses, setStudioClasses] = useState([]);
  const [studioLocation, setStudioLocation] = useState([]);

  const mobileSize = useMemo(() => window.screen.width < 576, []);

  const [propsPayment, setPropsPayment] = useState();
  const [openStripe, setOpenStripe] = useState(typeBoolean.FALSE);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { location } = useSelector((state) => state.location);
  const { payment_detail, student_plan } = useSelector((state) => state.owner);
  const { public_classes, class_list, isLoadingClasses, active_plan, status } =
    useSelector((state) => state.student);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const colorTheme = studio_detail?.styles?.header_color;
  const student_id = props.location.state?.student_id;
  const fromOwner = props.location.state?.fromOwner;
  const isBrowseClasses = props.location.state?.isBrowseClasses;
  const studio_location = fromOwner ? location : studioLocation;
  const class_offerings = isBrowseClasses ? public_classes : class_list;
  // const subdomain = "vedic-staging";
  const subdomain = window.location.host.split(".")[0];

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const activePlanData = fromOwner ? student_plan?.data : active_plan;
  const isSettlement = propsPayment?.payment?.payment_status === "settlement";

  useEffect(() => {
    handleGetDisbursementSetting();
    dispatch(setMenuActive("Classes"));
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();

    params.append(
      "start_date",
      moment(state[0].startDate).format("YYYY-MM-DD")
    );
    params.append("end_date", moment(state[0].endDate).format("YYYY-MM-DD"));

    if (classId) {
      params.append("class_ids", classId);
    }

    if (locationFilterId) {
      params.append("location_id", locationFilterId);
    }

    if (classCategoryId) {
      params.append("category_ids", classCategoryId);
    }

    if (fromOwner) {
      params.append("student_id", student_id);

      dispatch(getListOfferingClasses(user_token, studio_id, params));
      dispatch(
        getStudentPlan(user_token, studio_id, student_id, "status=active")
      );
    } else {
      dispatch(getListOfferingClasses(user_token, studio_id, params));
      dispatch(getMyActivePlan(user_token, studio_id));
    }
  }, [
    dispatch,
    fromOwner,
    state,
    student_id,
    studio_id,
    user_token,
    isSettlement,
    classId,
    locationFilterId,
    classCategoryId,
    modalSuccess,
  ]);

  useEffect(() => {
    handleGetPublicClasses();
  }, []);

  useEffect(() => {
    handleGetLocation();
  }, [selectedClassDetail?.class_id]);

  useEffect(() => {
    if (studio_detail?.id) {
      handleGetClassCategories();
    }
  }, [studio_detail?.id]);

  const showModalDateRange = () => {
    setIsModalDatOpeRange(true);
  };

  const handleOkDateRange = () => {
    setIsModalDatOpeRange(false);
  };

  const handleCancelDateRange = () => {
    setIsModalDatOpeRange(false);
  };

  const showModalDetailClass = () => {
    setIsModalOpenDetailClass(true);
  };

  const handleCancelDetailClass = () => {
    setIsModalOpenDetailClass(false);
    setProsessEndroll(false);
  };

  const columns = [
    {
      title: "Class Name",
      dataIndex: "class_name",
      key: "class_name",
      render: (text, record) => {
        return <p className="font-black">{text}</p>;
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacher",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return text ? (
          <Tag color="#27AE60">Online</Tag>
        ) : (
          <Tag color="#2D9CDB">Onsite</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        if (record.user_has_booked_class) {
          return (
            <div
              style={{ color: colorTheme }}
              className="button flex justify-center items-center"
            >
              Registered
            </div>
          );
        }
        return (
          <Button
            onClick={() => {
              setSelectedClassDetail(record);
              showModalDetailClass();
              setProsessEndroll(true);
            }}
            type="primary"
            title="Enroll Now"
            style={{
              backgroundColor: colorTheme || "#E26851",
              borderRadius: 0,
            }}
          />
        );
      },
    },
  ];

  const onChangeRadio = (e) => {
    setPaymentMethod(e.target.value);
  };

  // const handleStripe = (params) => {
  //   setPropsPayment(params);
  //   setOpenStripe(typeBoolean.TRUE);
  // };

  const handleGetPublicClasses = async () => {
    try {
      const { data } = await getPublicClasses(subdomain);
      setStudioClasses(data.data);
    } catch (error) {}
  };

  const handleGetLocation = async () => {
    try {
      const { data } = await getPublicStudioLocation(
        subdomain,
        selectedClassDetail?.class_id
      );
      setStudioLocation(data.data);
    } catch (error) {}
  };

  const handleGetClassCategories = async () => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      const { data } = await sendGetRequest(
        null,
        null,
        `/studios/${studio_detail.id}/categories`,
        config
      );
      setClassCatogories(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDisbursementSetting = async () => {
    try {
      const { data } = await getDisbursementSetting(user_token, studio_id);
      const { payment_gateway, recipient_bank_code } = data.data;

      setPaymentGateway(payment_gateway);
      setBankCode(recipient_bank_code);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusOrder = async () => {
    try {
      const { data } = await sendGetRequest(
        user_token,
        studio_id,
        `/orders/${propsPayment?._id}`
      );
      const { payment_status } = data?.data?.payment;
      if (payment_status === "settlement") {
        setPropsPayment();
      }
      setPropsPayment(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (propsPayment?._id) {
      const intervalId = setInterval(() => {
        getStatusOrder();
      }, 60000); // 60 seconds

      // Cleanup the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [propsPayment?._id]);

  return (
    <OrganismLayout isBrowseClasses={isBrowseClasses} maxWidth="42rem">
      <div className="px-8 md:px-2">
        <div className="flex justify-between">
          <h2 className="text-zinc-800 font-bold">Classes</h2>

          <Space direction={mobileSize ? "vertical" : "horizontal"}>
            <Space onClick={showModalDateRange}>
              <img alt="" src={calender} width={18} />
              <span className=" pointer custom-hover ms-2">
                {`${moment(state[0].startDate).format(
                  "DD MMM, YYYY"
                )} - ${moment(state[0].endDate).format("DD MMM, YYYY")}`}
              </span>
              <CaretDownOutlined className="text-[10px]" />
            </Space>

            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              size="large"
              className="w-[200px] shadow-sm custom-select"
              placeholder="Categories"
              suffixIcon={<CaretDownOutlined />}
              value={classCategoryId}
              onSelect={(e) => setClassCategoryId(e)}
              onClear={() => setClassCategoryId(null)}
              options={
                classCategories.map((item) => ({
                  label: capitalize(item.name),
                  value: item._id,
                })) || []
              }
            />

            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              size="large"
              className="w-[200px] shadow-sm custom-select"
              placeholder="Classes"
              suffixIcon={<CaretDownOutlined />}
              value={classId}
              onSelect={(e) => setClassId(e)}
              onClear={() => setClassId(null)}
              options={
                studioClasses.map((item) => ({
                  label: item.class_name,
                  value: item.id,
                })) || []
              }
            />

            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              size="large"
              className="w-[200px] shadow-sm custom-select"
              placeholder="Locations"
              suffixIcon={<CaretDownOutlined />}
              value={locationFilterId}
              onSelect={(e) => setLocationFilterId(e)}
              onClear={() => setLocationFilterId(null)}
              options={
                studio_location.map((item) => ({
                  label: item.name,
                  value: item.id,
                })) || []
              }
            />

            <Button
              type="primary"
              title="Reset Filter"
              style={{ backgroundColor: colorTheme }}
              onClick={() => {
                setState([
                  {
                    startDate: currentDate,
                    endDate: last,
                    key: "selection",
                  },
                ]);
                setClassId(null);
                setLocationFilterId(null);
                setClassCategoryId(null);
              }}
            />
          </Space>
        </div>

        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBorderRadius: 0,
                headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
              },
            },
          }}
        >
          <div className="gap-6">
            {class_list?.length > 0 ? (
              class_list.map((data, idx) => {
                const dataClass = data?.class_schedules.map((el, i) => {
                  return {
                    key: i,
                    class_name: el.class_name,
                    time: el.class_start_time + " - " + el.class_end_time,
                    teacher: el.teachers.join(),
                    type: el.is_online,
                    ...el,
                  };
                });
                return (
                  <div key={idx} className="py-9">
                    <div
                      style={{
                        borderColor: colorTheme,
                      }}
                      className="border border-opacity-30"
                    >
                      <div className="px-2 py-4 flex justify-between items-center font-semibold">
                        <div>{days[moment(data?.class_date).day()]}</div>
                        <div>
                          {moment(data?.class_date).format("DD MMMM, YYYY")}
                        </div>
                      </div>

                      <Table
                        loading={isLoadingClasses}
                        className="min-w-100"
                        size="middle"
                        columns={columns}
                        dataSource={dataClass}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptyData />
            )}
          </div>
        </ConfigProvider>
      </div>

      <Modal
        footer={null}
        open={isModalOpenDateRange}
        onOk={handleOkDateRange}
        onCancel={handleCancelDateRange}
      >
        <div className="py-2 text-center">
          <DateRange
            className="shadow-sm mb-3"
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <div className="d-flex justify-content-center ">
            <Button
              type="primary"
              title="Close"
              onClick={handleCancelDateRange}
              style={{
                borderRadius: 0,
                backgroundColor: colorTheme,
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={isModalOpenDetailClass}
        onCancel={handleCancelDetailClass}
        footer={null}
        width={700}
      >
        <ModalDetailClass
          studioLocation={studioLocation}
          setModalSuccess={setModalSuccess}
          propsPayment={propsPayment}
          setPropsPayment={setPropsPayment}
          bankCode={bankCode}
          paymentGateway={paymentGateway}
          prosessEndroll={prosessEndroll}
          setProsessEndroll={setProsessEndroll}
          selectedClassDetail={selectedClassDetail}
          colorTheme={colorTheme}
          locationId={locationId}
          setLocationId={setLocationId}
          confirmMethod={confirmMethod}
          activePlanData={activePlanData}
          onChangeRadio={onChangeRadio}
          paymentMethod={paymentMethod}
          fromOwner={fromOwner}
          student_id={student_id}
          setIsModalOpenDetailClass={setIsModalOpenDetailClass}
          setSelectedClassDetail={setSelectedClassDetail}
          setPaymentMethod={setPaymentMethod}
          setConfirmMethod={setConfirmMethod}
        />
      </Modal>

      <ModalSuccessBookingClass
        fromOwner={true}
        student_id={student_id}
        propsPayment={propsPayment}
        open={modalSuccess}
        colorTheme={colorTheme}
        setOpen={setModalSuccess}
        selectedClassDetail={selectedClassDetail}
      />
    </OrganismLayout>
  );
};

export default ClassIndex;
