import React from "react";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import AtomLoading from "../../../../components/loding-indicator";
import { ConfigProvider, Popover, Space, Table } from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";
import Button from "../../../../components/Button/button.component";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import Dot from "../../../../assets/images/3dots.svg";
const RoomLists = () => {
  const [cookies] = useCookies(["user", "subdomain"]);

  const { user, studio_detail } = useSelector((state) => state.user);

  const user_token = user?.token ?? cookies.user?.token;
  const studio_id = user?.studio_id ?? cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <span
            className=" cursor-pointer flex"
            //  onClick={() =>
            //    history.push(`/admin/student/${record?.key}/detail`)
            //  }
          >
            {record.avatar && (
              <img
                alt=""
                src={record?.avatar}
                className="w-6 h-6 rounded-full mx-1"
              />
            )}
            {text}
          </span>
        );
      },
    },
    {
      title: "Adults",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Children",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Total People",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Characteristics",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Options",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Channels",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Units",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Available",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Popover
            placement="bottomRight"
            content={() => (
              <Space direction="vertical">
                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  //  onClick={() =>
                  //    history.push({
                  //      pathname: `/admin/student/${record?.key}/detail`,
                  //    })
                  //  }
                >
                  Detail
                </span>
                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  //  onClick={() =>
                  //    history.push({
                  //      pathname: `/admin/student/${record?.key}/edit`,
                  //      state: { id: record?.key },
                  //    })
                  //  }
                >
                  Edit Profile
                </span>
                <span
                  onClick={() => {
                    // const data = await axiosInstance.get(
                    //   `/students/${record?.key}/plan?status=active`,
                    // );
                    // console.log("hello");
                    // console.log(data.data);
                    // setShow1({
                    //   id: record?.key,
                    //   visible: true,
                    //   name: record?.name,
                    //   email: record?.email,
                    // });
                  }}
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 text-red-600 font-semibold"
                >
                  Delete
                </span>
              </Space>
            )}
          >
            <img className="cursor-pointer" src={Dot} alt="" />
          </Popover>
        );
      },
    },
  ];
  return (
    <OrganismLayout title="Room Lists">
      {false ? (
        <AtomLoading />
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
              },
            },
          }}
        >
          <h2 className="font-bold">Room Lists</h2>
          <div className="w-full flex justify-end">
            <Button
              title="Add Room Lists"
              type="primary"
              style={{
                borderRadius: 0,
                backgroundColor: colorTheme,
                margin: "1rem 0",
              }}
              // onClick={() => {
              //   history.push("/admin/add-student");
              // }}
            />
          </div>
          <Table
            columns={columns}
            // dataSource={
            //   students.data &&
            //   students?.data.map((student) => {
            //     return {
            //       key: student.id,
            //       name: student.first_name + " " + student.last_name,
            //       email: student.email,
            //       phone: student.phone_number,
            //       upcoming: student.upcoming,
            //       attended: student.attended,
            //       avatar: student.profile_picture,
            //     };
            //   })
            // }
          />
        </ConfigProvider>
      )}
    </OrganismLayout>
  );
};

export default RoomLists;
