import React from "react";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import {
  Col,
  ConfigProvider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import InputText from "../../../../components/input/text";
import CustomTimePicker from "../../../../components/custom-timepicker";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Item } = Form;
const Property = () => {
  const [form] = useForm();

  const { studio_detail } = useSelector((state) => state.user);

  const colorTheme = studio_detail?.styles?.header_color;

  const SwitchComponent = ({ title, content, isTooltip, width }) => (
    <div className="flex justify-between items-center mb-4">
      <Space>
        <p className="m-0">{title}:</p>

        {isTooltip && (
          <Tooltip
            color="#FFF5D3"
            overlayInnerStyle={{ width: width ?? 580 }}
            title={content}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </Space>
      <Switch />
    </div>
  );

  return (
    <OrganismLayout title="Property">
      <h2 className="font-bold">Property Configuration</h2>
      <Row gutter={[128, 0]}>
        <Col xl={12}>
          <h5>Booking</h5>
          <div
            style={{
              border: `1px solid ${colorTheme}`,
              margin: "16px 0",
            }}
          />

          <SwitchComponent title="Booking Enabled" />

          <Form form={form} layout="vertical">
            <Item label="Booking Disabled Message:">
              <Input.TextArea rows={4}></Input.TextArea>
            </Item>

            <InputText
              required={false}
              name="admin_email"
              label="Admin Email"
            />

            <InputText
              required={false}
              name="sender_email"
              label="Sender Email"
            />

            <Item label="Check-in Time:">
              <Space>
                <CustomTimePicker />
                <CustomTimePicker />
              </Space>
            </Item>

            <Item label="Check-out Time:">
              <Space>
                <CustomTimePicker />
                <CustomTimePicker />
              </Space>
            </Item>

            <Item
              label={
                <Space>
                  <span>Bookings Modification/Cancellation:</span>
                  <Tooltip
                    color="#FFF5D3"
                    overlayInnerStyle={{ width: 580 }}
                    title={
                      <div className="text-[#222222]">
                        <h6>Bookings Modification/Cancellation</h6>
                        <p>
                          Choose whether the customers should be allowed to
                          modify or cancel their reservations via front-end. If
                          Disabled, with Request, customers will only be able to
                          contact the property by sending a message. It is also
                          possible to define a minimum number of days in advance
                          to make a modification or a cancellation. The
                          refundable settings of the selected Types of Price
                          will be always applied, regardless of this limit.
                        </p>
                      </div>
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
            >
              <Select size="large"></Select>
            </Item>

            <div className="flex gap-4 mb-4">
              <div className="border border-solid border-[#FFFEFC]" />

              <InputText
                required={false}
                name="min_days"
                label="Minimum days in Advanced"
                width={80}
              />
            </div>

            <SwitchComponent title="Bookings for today at any time" />
            <SwitchComponent title="Enable Coupons" />
            <SwitchComponent
              isTooltip
              content={
                <div className="text-[#222222]">
                  <h6>Auto-Assign Room Units</h6>
                  <p>
                    The room types (with more than 1 unit) using the Units
                    Distinctive Features, can automatically assign the first
                    available room unit to the bookings. For example: new
                    bookings can be automatically assigned to the room number
                    #101, #102, #103 etc..
                  </p>
                </div>
              }
              title="Auto-assign Room Units"
            />
            <SwitchComponent
              isTooltip
              content={
                <div className="text-[#222222]">
                  <h6>Enable Upselling Extras</h6>
                  <p>
                    If enabled, non-cancelled bookings with a check-out date in
                    the future will allow the customers to add some
                    options/extra services to their reservation. This way it
                    will be possible for the customers to order extra services
                    for the rooms booked and add them to their reservations.
                    This is valid for all reservations, the ones coming from
                    your website as well as the ones downloaded by the Channel
                    Manager from the various OTAs.
                  </p>
                </div>
              }
              title="Enable Upselling Extras"
            />
          </Form>
        </Col>

        <Col xl={12}>
          <h5>Search Parameters</h5>

          <div
            style={{
              border: `1px solid ${colorTheme}`,
              margin: "16px 0",
            }}
          />

          <Form form={form} layout="vertical">
            <InputText
              required={false}
              name="days_booking"
              label="Days in Advance for bookings"
            />

            <InputText
              required={false}
              name="min_number_of_nights"
              label="Minimum Number of Nights"
            />

            <InputText
              required={false}
              name="number_rooms"
              label="Number of Rooms"
            />

            <Item label="Number of Adults:">
              <div className="flex">
                <div className="flex items-center gap-2">
                  <span>From</span>
                  <Input size="large" className="w-20" />
                </div>
                <div className="flex items-center gap-2 ml-2">
                  <span>To</span>
                  <Input size="large" className="w-20" />
                </div>
              </div>
            </Item>

            <Item label="Number of Children:">
              <div className="flex">
                <div className="flex items-center gap-2">
                  <span>From</span>
                  <Input size="large" className="w-20" />
                </div>
                <div className="flex items-center gap-2 ml-2">
                  <span>To</span>
                  <Input size="large" className="w-20" />
                </div>
              </div>
            </Item>

            <Item label="Maximum Date in the Future from today:">
              <div className="flex gap-2">
                <Input size="large" style={{ width: "80px" }} />
                <Select size="large" style={{ width: "80px" }} />
              </div>
            </Item>

            <Item
              label={
                <Space>
                  <span>Closing Dates</span>
                  <Tooltip
                    color="#FFF5D3"
                    overlayInnerStyle={{ width: 400 }}
                    title={
                      <div className="text-[#222222]">
                        <h6>Closing Dates</h6>
                        <p>
                          The property closing dates are settings defined at
                          account-level for the booking engine of the website.
                          The closed dates will not be selectable from the
                          calendars in the front-end. However, these settings
                          will NOT apply to the channels connected through the
                          Channel Manager (unless you submit the Bulk Action).
                          You should rather close the rooms from the page
                          Calendar if you wanted the Channel Manager to
                          immediately close the availability on the OTAs as
                          well.
                        </p>
                      </div>
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
            ></Item>

            <Item label="Suggest solutions when no availability:">
              <Select size="large" className="w-20"></Select>
            </Item>

            <SwitchComponent title="Choose Rooms Category" />
            <SwitchComponent
              isTooltip
              width={400}
              content={
                <div className="text-[#222222]">
                  <h6>Show Number of Children</h6>
                  <p>
                    If enabled, every booking form will ask the guests to choose
                    the number of children for each room party, beside the
                    number of adults. Turn it off if children are not allowed or
                    if they are charged like adults.
                  </p>
                </div>
              }
              title="Show Number of Children"
            />
          </Form>
        </Col>
      </Row>
    </OrganismLayout>
  );
};

export default Property;
