import "./App.scss";

import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { configureStore, configurePersistor } from "./redux/store";
import { CookiesProvider } from "react-cookie";
const store = configureStore();
const persistor = configurePersistor(store);

function App() {
  // window.addEventListener("message", (event) => {
  //   // Validate the origin of the message
  //   if (event.origin === "https://vedic.app.s360.is") {
  //     if (event.data === "getLocalStorage") {
  //       // Send localStorage data back to the parent
  //       event.source.postMessage(
  //         localStorage.getItem("persist:root"),
  //         event.origin
  //       );
  //     }
  //   }
  //   console.log("Message received:", event.data, "from origin:", event.origin);
  // });

  return (
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <CookiesProvider>
            <Routes />
          </CookiesProvider>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
