import React, { useContext, useEffect, useMemo, useState } from "react";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import AtomLoading from "../../../../components/loding-indicator";
import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";
import CustomButton from "../../../../components/Button/button.component";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import Dot from "../../../../assets/images/3dots.svg";
import { AppleOutlined, HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  useSortable,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { useHistory } from "react-router-dom";

const RowContext = React.createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const TableRow = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const initialData = [
  {
    key: "1",
    id: "101",
    name: "Luxury Suite",
    description: "A premium suite with ocean view",
    price: "$300",
    taxRate: "10%",
    perNight: "Yes",
    perPerson: "No",
    allowedQuantity: "1",
    image:
      "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
  },
  {
    key: "2",
    id: "102",
    name: "Deluxe Room",
    description: "A comfortable deluxe room for families",
    price: "$200",
    taxRate: "8%",
    perNight: "Yes",
    perPerson: "No",
    allowedQuantity: "2",
    image:
      "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
  },
  {
    key: "3",
    id: "103",
    name: "Standard Room",
    description: "A budget-friendly room with basic amenities",
    price: "$100",
    taxRate: "5%",
    perNight: "Yes",
    perPerson: "Yes",
    allowedQuantity: "4",
    image:
      "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
  },
];

const Extras = () => {
  const history = useHistory();

  const [form] = Form.useForm();

  const [cookies] = useCookies(["user", "subdomain"]);

  const { user, studio_detail } = useSelector((state) => state.user);

  const user_token = user?.token ?? cookies.user?.token;
  const studio_id = user?.studio_id ?? cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const [dataSource, setDataSource] = useState(initialData);
  const [action, setAction] = useState({
    add: false,
    edit: false,
    detail: false,
    id: "",
    record: {},
  });
  const [actDel, setActDel] = useState({
    id: "",
    visible: false,
    name: "",
    desc: "",
  });

  useEffect(() => {
    if (action.edit) {
      form.setFieldsValue({
        amenity_name: action.name,
        icon: action.icon,
        room_assigned: action.room_assigned,
      });
    }
  }, [action.edit, action.icon, action.name, action.room_assigned, form]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.key === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const columns = [
    {
      title: "Change order",
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Tax Rate",
      dataIndex: "taxRate",
    },
    {
      title: "Per Night",
      dataIndex: "perNight",
    },
    {
      title: "Per Person",
      dataIndex: "perPerson",
    },
    {
      title: "Allowed Quantity",
      dataIndex: "allowedQuantity",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (imageUrl) => (
        <a href={imageUrl} target="_blank" rel="noreferrer">
          View Image
        </a>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <Popover
            placement="bottomRight"
            content={() => (
              <Space direction="vertical" className="w-full">
                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  onClick={() =>
                    setAction((prev) => ({
                      ...prev,
                      detail: true,
                      id: record.id,
                      record,
                    }))
                  }
                >
                  View Detail
                </span>
                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  onClick={() =>
                    history.push(`/admin/rooms/modify-extras/edit/${record.id}`)
                  }
                >
                  Edit
                </span>
                <span
                  onClick={() => {
                    setActDel((prev) => ({
                      ...prev,
                      visible: true,
                      id: record.id,
                      record,
                    }));
                  }}
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 text-red-600 font-semibold"
                >
                  Delete
                </span>
              </Space>
            )}
          >
            <img className="cursor-pointer" src={Dot} alt="" />
          </Popover>
        );
      },
    },
  ];

  const detailExtras = [
    { label: "Price", value: action.record.price },
    { label: "Tax Rate", value: action.record.taxRate },
    { label: "Cost per Night", value: action.record.price },
    { label: "Maximum Cost", value: "$999" },
    {
      label: "Only for Children",
      value: ["Age 1-2: Charge $2", "Age 1-22: Charge $3"],
    },
    {
      label: "Minimum guests filtering",
      value: ["Apply only if guests are more than: 0"],
    },
    { label: "Always available", value: "Yes" },
  ];

  const settingExtras = [
    { label: "It's a City Tax", value: "Yes" },
    { label: "It’s a Fee", value: "Yes" },
    { label: "Pet fee", value: "Yes" },
    { label: "Pet It’s a damage deposit", value: "Yes" },
    {
      label: "Description",
      value:
        "Ultrices tortor at dui pellentesque nunc. Molestie in tincidunt viverra ultricies sit. Sed semper massa in tempus vitae in nunc nibh.",
    },
    { label: "Room assigned", value: ["Bigger Rooms", "Doubles", "Single"] },
  ];

  return (
    <OrganismLayout title="Extras">
      {false ? (
        <AtomLoading />
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
              },
            },
          }}
        >
          <h2 className="font-bold">Extras</h2>
          <div className="w-full flex justify-end">
            <CustomButton
              title="Add Extras"
              type="primary"
              style={{
                borderRadius: 0,
                backgroundColor: colorTheme,
                margin: "1rem 0",
              }}
              onClick={() => history.push("/admin/rooms/modify-extras/add")}
            />
          </div>

          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={dataSource.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                rowKey="key"
                components={{
                  body: {
                    row: TableRow,
                  },
                }}
                columns={columns}
                dataSource={dataSource}
              />
            </SortableContext>
          </DndContext>
        </ConfigProvider>
      )}

      <Modal
        centered
        open={actDel.visible}
        footer={null}
        closable={false}
        onCancel={() => setActDel((prev) => ({ ...prev, visible: false }))}
      >
        <div className="text-center p-3 flex flex-col items-center gap-2">
          <h2 className=" font-bold">Delete Extra</h2>

          <div className="border border-slate-300 w-full">
            <p className="m-0 p-4">
              <b style={{ color: colorTheme }}>{actDel.name}</b>
              <br />
              <br />
              <span className="text-[#475569]">{actDel.desc}</span>
            </p>
          </div>

          <div className="flex flex-col gap-3 mt-4">
            <CustomButton
              title="DELETE"
              type="primary"
              // onClick={() => handleDelete()}
              style={{
                backgroundColor: "#EB5757",
                borderRadius: 0,
              }}
            />

            <CustomButton
              title="Cancel"
              type="outline-secondary"
              onClick={() => setActDel((prev) => ({ ...prev, visible: false }))}
              style={{
                backgroundColor: "white",
                color: colorTheme,
                border: "none",
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        open={action.detail}
        footer={null}
        closable={false}
        onCancel={() => {
          setAction((prev) => ({
            ...prev,
            detail: false,
          }));
        }}
      >
        <div className="flex justify-between mb-12">
          <h2>{action.record.name}</h2>
          <img
            className="object-contain"
            src={action.record.image}
            alt=""
            width={150}
            height={100}
          />
        </div>

        <h5>Details</h5>
        {detailExtras.map((detail) => (
          <div className="flex gap-4 mb-2">
            <b className="w-1/3">{detail.label}</b>
            {Array.isArray(detail.value) ? (
              <Space direction="vertical">
                <span>Yes</span>

                <ul>
                  {detail.value.map((x) => (
                    <li>{x}</li>
                  ))}
                </ul>
              </Space>
            ) : (
              <span>{detail.value}</span>
            )}
          </div>
        ))}
        <Divider />
        <h5>Settings</h5>
        {settingExtras.map((set) => (
          <div className="flex gap-4 mb-2">
            <b className="w-1/3">{set.label}</b>
            {Array.isArray(set.value) ? (
              <Space>
                {set.value.map((x) => (
                  <div className="bg-[#ECF1F8] px-2 py-1 text-[#475569] font-semibold">
                    <span>{x}</span>
                  </div>
                ))}
              </Space>
            ) : (
              <span className="w-fit">{set.value}</span>
            )}
          </div>
        ))}

        <Row justify="center" className="mt-12">
          <CustomButton
            title="Back"
            type="primary"
            onClick={() =>
              setAction((prev) => ({
                ...prev,
                detail: false,
              }))
            }
            style={{
              backgroundColor: colorTheme,
              borderRadius: 0,
            }}
          />
        </Row>
      </Modal>
    </OrganismLayout>
  );
};

export default Extras;
