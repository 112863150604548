import React from "react";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import {
  Col,
  ConfigProvider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import InputText from "../../../../components/input/text";
import CustomTimePicker from "../../../../components/custom-timepicker";
import { InfoCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { paramsWP } from "../../../../services/auth";

const { Item } = Form;
const ModifyExtras = () => {
  const [form] = useForm();

  const { studio_detail } = useSelector((state) => state.user);

  const colorTheme = studio_detail?.styles?.header_color;

  const SwitchComponent = ({ title, content, isTooltip, width }) => (
    <div className="flex justify-between items-center mb-4">
      <Space>
        <p className="m-0">{title}:</p>

        {isTooltip && (
          <Tooltip
            color="#FFF5D3"
            overlayInnerStyle={{ width: width ?? 580 }}
            title={content}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </Space>
      <Switch />
    </div>
  );

  const isCreating = window.location.pathname.split("/")[4] === "add";

  return (
    <OrganismLayout title="Add Extras">
      <h2 className="font-bold">{isCreating ? "Add" : "Edit"} Extras</h2>
      <Row gutter={[128, 0]}>
        <Col xl={12}>
          <h5>Details</h5>
          <div
            style={{
              border: `1px solid ${colorTheme}`,
              margin: "16px 0",
            }}
          />

          <Form form={form} layout="vertical">
            <InputText
              required={false}
              name="extras_name"
              label="Extras Name"
            />

            <InputText
              required={false}
              name="sender_email"
              label="Sender Email"
            />

            <Item label="Option Price:">
              <div className="flex gap-2 w-full">
                <InputNumber size="large" className="w-full" placeholder="00" />
                <Select size="large" placeholder="$" />
              </div>
            </Item>

            <Item label="Tax Rate:">
              <Select size="large" placeholder="VAT Municipal 10%"></Select>
            </Item>

            <SwitchComponent title="Cost per Night" />

            <InputText
              prefix="$"
              type="number"
              required={false}
              name="max_cost"
              label="Maximum Cost"
            />

            <SwitchComponent title="Only for Children" />

            <div className="flex gap-4 mb-4">
              <div className="border border-solid border-[#FFFEFC]" />

              <div className="grid">
                <p className="text-[12px]">
                  In case of Charges per children age, add age intervals and
                  costs (fixed or percentage values of adults tariffs)
                </p>

                <div className="flex mb-4">
                  <Space>
                    <span>Age:</span>
                    <Input size="large" className="w-10" />
                    -
                    <Input size="large" className="w-10" />
                  </Space>

                  <Space className="ml-2" align="center">
                    <span>Charge:</span>
                    <Input size="large" className="w-20" />
                    <Select size="large" placeholder="$" />
                    <MinusCircleOutlined
                      className="text-3xl"
                      style={{ color: colorTheme }}
                    />
                  </Space>
                </div>

                <p className="text-[12px]">
                  For a better compatibility with OTAs, it is recommended to
                  define at most 3 age intervals (buckets), where the ‘to age’
                  is inclusive, and the next from-age interval should be greater
                  by one (i.e. from 0 to 3, from 4 to 8, from 9 to 16). Also,
                  fixed amounts in your currency are way more compatible than
                  percent values of the room/adults rate.
                </p>
              </div>
            </div>

            <SwitchComponent title="Minimum guests filtering" />

            <div className="flex gap-4">
              <div className="border border-solid border-[#FFFEFC]" />

              <div className="grid w-full">
                <Item label="Apply only if guests are more than:">
                  <div className="flex gap-2 w-full">
                    <InputNumber
                      size="large"
                      className="w-full"
                      placeholder="00"
                    />
                    <Select size="large" placeholder="Guests" />
                  </div>
                </Item>

                <Item
                  label="Apply only if guests are less than:"
                  className="mb-0"
                >
                  <div className="flex gap-2 w-full">
                    <InputNumber
                      size="large"
                      className="w-full"
                      placeholder="00"
                    />
                    <Select size="large" placeholder="Guests" />
                  </div>
                </Item>
              </div>
            </div>
            <br />
            <SwitchComponent title="Always available" />
          </Form>
        </Col>

        <Col xl={12}>
          <h5>Settings</h5>

          <div
            style={{
              border: `1px solid ${colorTheme}`,
              margin: "16px 0",
            }}
          />

          <p className="text-[12px]">
            These settings are needed by the system to know if this Option is a
            City Tax like local/tourist taxes, or if it’s a Fee like a booking
            fee, a mandatory resort fee, or a gratuity service fee that has to
            be paid at time of booking. Leave these settings disabled if the
            Option is not a tax or a fee, but just an extra service.
          </p>

          <Form form={form} layout="vertical">
            <SwitchComponent title="it's a City Tax" />
            <SwitchComponent title="It’s a Fee" />
            <SwitchComponent title="Pet Fee" />
            <SwitchComponent title="Pet It’s a damage deposit" />

            <Item label="Description:">
              <Input.TextArea rows={4}></Input.TextArea>
            </Item>

            <Item name="room_assigned" label="Room assigned:">
              <Select
                mode="multiple"
                size="large"
                // disabled={action.detail}
                showSearch
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "1",
                    label: "Bigger Room",
                  },
                  {
                    value: "2",
                    label: "Camping Sites",
                  },
                  {
                    value: "3",
                    label: "Doubles",
                  },
                ]}
              />
            </Item>
          </Form>
        </Col>
      </Row>
    </OrganismLayout>
  );
};

export default ModifyExtras;
