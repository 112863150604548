import React, { useCallback, useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import AtomLoading from "../../../../components/loding-indicator";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { deleteTeacher, getAllTeachers } from "../../../../services/teachers";
import { ConfigProvider, Modal, Popover, Space, Table } from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";
import Dot from "../../../../assets/images/3dots.svg";

export const Teachers = () => {
  const history = useHistory();

  const [cookies, setCookies] = useCookies(["user", "subdomain"]);
  const [active, setActive] = useState(1);
  const [show1, setShow1] = useState({
    id: "",
    visible: false,
    name: "",
    email: "",
  });
  const [teachers, setTeachers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const handleDelete = async () => {
    try {
      await deleteTeacher(user_token, studio_id, show1.id);

      setShow1({ visible: false });
      history.push("/admin/Teachers");
      toast.success("Teacher account is successfully deleted!");
      getTeachers();
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <span
            className=" cursor-pointer flex"
            onClick={() => history.push(`/admin/teacher/${record?.key}/detail`)}
          >
            {record.avatar && (
              <img
                alt=""
                src={record?.avatar}
                className="w-6 h-6 rounded-full mx-1"
              />
            )}
            {text}
          </span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    // {
    //   title: "Upcoming",
    //   dataIndex: "upcoming",
    //   key: "upcoming",
    // },
    // {
    //   title: "Attended",
    //   dataIndex: "attended",
    //   key: "attended",
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Popover
            placement="bottomRight"
            content={() => (
              <Space direction="vertical">
                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  onClick={() =>
                    history.push({
                      pathname: `/admin/teacher/${record?.key}/detail`,
                      state: { id: record?.key },
                    })
                  }
                >
                  Detail
                </span>

                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  onClick={() =>
                    history.push({
                      pathname: `/admin/teacher/${record?.key}/edit`,
                      state: { id: record?.key },
                    })
                  }
                >
                  Edit Profile
                </span>

                <span
                  onClick={() => {
                    setShow1({
                      id: record?.key,
                      visible: true,
                      name: record?.name,
                      email: record?.email,
                    });
                  }}
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 text-red-600 font-semibold"
                >
                  Delete
                </span>
              </Space>
            )}
          >
            <img className="cursor-pointer" src={Dot} alt="" />
          </Popover>
        );
      },
    },
  ];

  const getTeachers = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getAllTeachers(user_token, studio_id, active);

      setTeachers(res.data.data);
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setIsLoading(false);
    }
  }, [active, studio_id, user_token]);

  useEffect(() => {
    getTeachers();
  }, [active, getTeachers]);

  return (
    <>
      <OrganismLayout title="Teachers" maxWidth="44rem">
        {isLoading ? (
          <AtomLoading />
        ) : (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
                },
              },
            }}
          >
            <h2 className="font-bold">Teacher</h2>
            <div className="w-full flex justify-end mb-4">
              <Button
                type="outline-secondary"
                title="Add Teacher"
                style={{
                  border: `1px solid ${colorTheme}`,
                  color: colorTheme,
                  borderRadius: 0,
                }}
                onClick={() => {
                  history.push("/admin/add-teacher");
                }}
              />
            </div>
            <Table
              columns={columns}
              dataSource={teachers?.map((item) => {
                return {
                  key: item.id,
                  name: item.first_name + " " + item.last_name,
                  email: item.email,
                  phone: item.phone_number,
                  // upcoming: item.upcoming,
                  // attended: item.attended,
                  avatar: item.profile_picture,
                };
              })}
            />
          </ConfigProvider>
        )}
      </OrganismLayout>

      <Modal open={show1?.visible} footer={null}>
        <div className="text-center p-3 flex flex-col items-center gap-2">
          <h1 className=" font-bold">Delete Teacher</h1>
          <p className="text-[20px] font-semibold">Delete this Teacher?</p>
          <div className="border border-slate-300 w-full">
            <p className="m-0 p-4">
              <b style={{ color: colorTheme }}>{show1?.name}</b>

              <br />
              <span className="text-[#475569]">{show1?.email}</span>
            </p>
          </div>
          <div
            style={{ border: "1px solid #E2685147" }}
            className="text-center p-3"
          >
            <b>
              Please delete all class assignments related to the teacher before
              deleting the teacher account
            </b>
          </div>

          <div className="flex flex-col gap-3 mt-4">
            <Button
              title="DELETE"
              type="primary"
              onClick={() => handleDelete()}
              style={{
                backgroundColor: "#EB5757",
                borderRadius: 0,
              }}
            />

            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow1({ visible: false })}
              style={{
                backgroundColor: "white",
                color: colorTheme,
                border: "none",
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
