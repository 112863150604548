import React, { useEffect } from "react";
import { Checkbox, Col, ConfigProvider, Form, Row } from "antd";
import TimePickerRange from "./TimePickerRange";
import DelForm from "../assets/images/deleteForm.svg";
import { PlusOutlined } from "@ant-design/icons";

export default function ScheduleFormlist({ name, colorTheme, form }) {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item key={field.key}>
              <Row justify="space-between" align="middle">
                <Col span={4}>
                  {index === 0 && (
                    <Form.Item
                      name={[field.name, "day"]}
                      valuePropName="checked"
                      noStyle
                      initialValue={form.getFieldValue([`${name}Checked`])}
                    >
                      <Checkbox>{name}</Checkbox>
                    </Form.Item>
                  )}
                </Col>

                <Col>
                  <Form.Item
                    name={[field.name, "timeRange"]}
                    className="w-full m-0"
                  >
                    <TimePickerRange
                      start_time={form.getFieldValue([
                        name,
                        field.name,
                        "startTime",
                      ])}
                      end_time={form.getFieldValue([
                        name,
                        field.name,
                        "endTime",
                      ])}
                    />
                  </Form.Item>
                </Col>

                <Col>
                  {fields.length > 1 && index !== 0 && (
                    <img
                      alt="delete"
                      src={DelForm}
                      className="cursor-pointer z-50"
                      onClick={() => remove(field.name)}
                    />
                  )}
                  {index === 0 && (
                    <div
                      style={{ backgroundColor: colorTheme }}
                      className="rounded-full h-10 w-10 flex justify-center items-center cursor-pointer"
                      onClick={() => add()}
                    >
                      <PlusOutlined className="text-white text-lg" />
                    </div>
                  )}
                </Col>
              </Row>
            </Form.Item>
          ))}
        </>
      )}
    </Form.List>
  );
}
