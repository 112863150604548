import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./login.styles.scss";

import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import { setStatusVerifyDefault } from "../../redux/user/user.action";
import FormOTP from "../../components/OTP";

const VerifyOtp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, login_email, loginFromStudio } = useSelector(
    (state) => state.user
  );

  const isReactivate = props.location.state?.isReactivate;
  const subdomain =
    process.env.NODE_ENV === "development"
      ? "vedic-staging"
      : window.location.host.split(".")[0];
  const emailFromRegister = props.location.state?.emailFromRegister;

  useEffect(() => {
    if (user?.token && user?.role === "superadmin") {
      history.push("/banks");
      dispatch(setStatusVerifyDefault());
      return;
    }

    if (user?.token && subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
      history.replace("/choose-studio");
      dispatch(setStatusVerifyDefault());
    }
  }, [dispatch, history, subdomain, user?.role, user?.token]);

  useEffect(() => {
    if (user?.token) {
      const { email, studio_id, token } = user;

      history.replace("/dashboard");
      window.parent.postMessage(
        { email, studio_id, token },
        "https://vedic.app.s360.is"
      );
    }
  }, [user?.token, history, user]);

  return (
    <Container fluid className="bg-light">
      <Row style={{ minHeight: "100vh" }}>
        <Col lg={4} className="mx-auto p-5">
          <FormOTP
            isReactivate={isReactivate}
            subdomain={subdomain}
            emailFromRegister={emailFromRegister}
          />
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default VerifyOtp;
