import React, { useContext, useEffect, useMemo, useState } from "react";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import AtomLoading from "../../../../components/loding-indicator";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Popover,
  Select,
  Space,
  Table,
} from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";
import CustomButton from "../../../../components/Button/button.component";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import Dot from "../../../../assets/images/3dots.svg";
import { AppleOutlined, HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  useSortable,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import InputText from "../../../../components/input/text";

const RowContext = React.createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const initialData = [
  {
    key: "1",
    name: "TV",
    icon: <AppleOutlined className="text-[32px]" />,
    room_assigned: [
      { label: "BiggerRoom", value: "1" },
      { label: "Camping Sites", value: "2" },
      { label: "Doubles", value: "3" },
    ],
  },
  {
    key: "2",
    name: "AC",
    icon: <AppleOutlined className="text-[32px]" />,
    room_assigned: [
      { label: "BiggerRoom", value: "1" },
      { label: "Camping Sites", value: "2" },
    ],
  },
];

const Amenities = () => {
  const [form] = Form.useForm();
  const [cookies] = useCookies(["user", "subdomain"]);

  const { user, studio_detail } = useSelector((state) => state.user);

  const user_token = user?.token ?? cookies.user?.token;
  const studio_id = user?.studio_id ?? cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const [dataSource, setDataSource] = useState(initialData);
  const [action, setAction] = useState({
    add: false,
    edit: false,
    detail: false,
    id: "",
    name: "",
    icon: "",
    room_assigned: "",
  });
  const [actDel, setActDel] = useState({
    id: "",
    visible: false,
    name: "",
    icon: "",
  });

  useEffect(() => {
    if (action.edit) {
      form.setFieldsValue({
        amenity_name: action.name,
        icon: action.icon,
        room_assigned: action.room_assigned,
      });
    }
  }, [action.edit, action.icon, action.name, action.room_assigned, form]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.key === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const columns = [
    {
      title: "Change order",
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Amenity Name",
      dataIndex: "name",
    },
    {
      title: "Amenity Icon",
      dataIndex: "icon",
      // render: () => ()
    },
    {
      title: "Rooms assigned",
      dataIndex: "room_assigned",
      render: (_, record) => (
        <Space>
          {record.room_assigned.map((x) => (
            <div className="bg-[#ECF1F8] px-2 py-1 text-[#475569] font-semibold">
              <span>{x.label}</span>
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Popover
            placement="bottomRight"
            content={() => (
              <Space direction="vertical" className="w-full">
                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  onClick={() =>
                    setAction((prev) => ({
                      ...prev,
                      detail: true,
                      id: record.id,
                      name: record.name,
                      icon: "a1",
                      room_assigned: record.room_assigned,
                    }))
                  }
                >
                  View Detail
                </span>
                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  onClick={() =>
                    setAction((prev) => ({
                      ...prev,
                      edit: true,
                      id: record.id,
                      name: record.name,
                      icon: "a1",
                      room_assigned: record.room_assigned,
                    }))
                  }
                >
                  Edit
                </span>
                <span
                  onClick={() => {
                    setActDel((prev) => ({
                      ...prev,
                      visible: true,
                      id: record.id,
                      name: record.name,
                      icon: "a1",
                    }));
                  }}
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 text-red-600 font-semibold"
                >
                  Delete
                </span>
              </Space>
            )}
          >
            <img className="cursor-pointer" src={Dot} alt="" />
          </Popover>
        );
      },
    },
  ];

  console.log(action);

  return (
    <OrganismLayout title="Amenities">
      {false ? (
        <AtomLoading />
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
              },
            },
          }}
        >
          <h2 className="font-bold">Amenities</h2>
          <div className="w-full flex justify-end">
            <CustomButton
              title="Add Amenities"
              type="primary"
              style={{
                borderRadius: 0,
                backgroundColor: colorTheme,
                margin: "1rem 0",
              }}
              onClick={() => {
                setAction((prev) => ({ ...prev, add: true }));
              }}
            />
          </div>
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={dataSource.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                rowKey="key"
                components={{
                  body: {
                    row: Row,
                  },
                }}
                columns={columns}
                dataSource={dataSource}
              />
            </SortableContext>
          </DndContext>
        </ConfigProvider>
      )}

      <Modal
        centered
        open={actDel.visible}
        footer={null}
        closable={false}
        onCancel={() => setActDel((prev) => ({ ...prev, visible: false }))}
      >
        <div className="text-center p-3 flex flex-col items-center gap-2">
          <h2 className=" font-bold">Delete Amenity</h2>

          <div className="border border-slate-300 w-full">
            <p className="m-0 p-4">
              {actDel.icon}

              <br />
              <br />
              <b style={{ color: colorTheme }}>{actDel.name}</b>
            </p>
          </div>

          <div className="flex flex-col gap-3 mt-4">
            <CustomButton
              title="DELETE"
              type="primary"
              // onClick={() => handleDelete()}
              style={{
                backgroundColor: "#EB5757",
                borderRadius: 0,
              }}
            />

            <CustomButton
              title="Cancel"
              type="outline-secondary"
              onClick={() => setActDel((prev) => ({ ...prev, visible: false }))}
              style={{
                backgroundColor: "white",
                color: colorTheme,
                border: "none",
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        open={action.add || action.edit || action.detail}
        footer={null}
        closable={false}
        onCancel={() => {
          setAction((prev) => ({
            ...prev,
            add: false,
            edit: false,
            detail: false,
          }));
          form.resetFields();
        }}
      >
        <div>
          <h2 className="text-center font-bold mb-8">
            {action.add ? "Add" : "Edit"} Amenity
          </h2>

          <Form form={form} layout="vertical">
            <InputText
              required={false}
              name="amenity_name"
              label="Amenity Name"
              readOnly={action.detail}
            />

            <Form.Item name="icon" label="Icon:">
              <Select
                style={{ width: 115 }}
                size="large"
                disabled={action.detail}
                options={[{ label: <AppleOutlined />, value: "a1" }]}
              />
            </Form.Item>

            <Form.Item name="room_assigned" label="Room assigned:">
              <Select
                mode="multiple"
                size="large"
                disabled={action.detail}
                showSearch
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "1",
                    label: "Bigger Room",
                  },
                  {
                    value: "2",
                    label: "Camping Sites",
                  },
                  {
                    value: "3",
                    label: "Doubles",
                  },
                ]}
              />
            </Form.Item>

            <div className="flex flex-col items-center gap-3 mt-4">
              <CustomButton
                title="Save"
                type="primary"
                // onClick={() => handleDelete()}
                style={{
                  backgroundColor: colorTheme,
                  borderRadius: 0,
                }}
              />

              <CustomButton
                title="Cancel"
                type="outline-secondary"
                onClick={() => {
                  setAction((prev) => ({
                    ...prev,
                    add: false,
                    edit: false,
                    detail: false,
                  }));
                  form.resetFields();
                }}
                style={{
                  backgroundColor: "white",
                  color: colorTheme,
                  border: "none",
                }}
              />
            </div>
          </Form>
        </div>
      </Modal>
    </OrganismLayout>
  );
};

export default Amenities;
