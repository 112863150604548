import React, { useState } from "react";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import AtomLoading from "../../../../components/loding-indicator";
import {
  ConfigProvider,
  Form,
  Input,
  Modal,
  Popover,
  Space,
  Table,
} from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";
import Button from "../../../../components/Button/button.component";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import Dot from "../../../../assets/images/3dots.svg";
import InputText from "../../../../components/input/text";

const Category = () => {
  const [cookies] = useCookies(["user", "subdomain"]);

  const [action, setAction] = useState({
    add: false,
    edit: false,
    detail: false,
  });
  const [actDel, setActDel] = useState({
    id: "",
    visible: false,
    name: "",
    desc: "",
  });

  const { user, studio_detail } = useSelector((state) => state.user);

  const user_token = user?.token ?? cookies.user?.token;
  const studio_id = user?.studio_id ?? cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const columns = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Popover
            placement="bottomRight"
            content={() => (
              <Space direction="vertical" className="w-full">
                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  onClick={() =>
                    setAction((prev) => ({
                      ...prev,
                      detail: true,
                      id: record.id,
                      name: record.name,
                      desc: record.description,
                    }))
                  }
                >
                  View Detail
                </span>
                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  onClick={() =>
                    setAction((prev) => ({
                      ...prev,
                      edit: true,
                      id: record.id,
                      name: record.name,
                      desc: record.description,
                    }))
                  }
                >
                  Edit
                </span>
                <span
                  onClick={() => {
                    setActDel((prev) => ({
                      ...prev,
                      visible: true,
                      id: record.id,
                      name: record.name,
                      desc: record.description,
                    }));
                  }}
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 text-red-600 font-semibold"
                >
                  Delete
                </span>
              </Space>
            )}
          >
            <img className="cursor-pointer" src={Dot} alt="" />
          </Popover>
        );
      },
    },
  ];

  return (
    <OrganismLayout title="Category">
      {false ? (
        <AtomLoading />
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
              },
            },
          }}
        >
          <h2 className="font-bold">Category</h2>
          <div className="w-full flex justify-end">
            <Button
              title="Add Category"
              type="primary"
              style={{
                borderRadius: 0,
                backgroundColor: colorTheme,
                margin: "1rem 0",
              }}
              onClick={() => {
                setAction((prev) => ({ ...prev, add: true }));
              }}
            />
          </div>
          <Table
            columns={columns}
            dataSource={[
              { id: 1, name: "Bigger Rooms", description: "Category" },
            ]}
          />
        </ConfigProvider>
      )}

      <Modal
        centered
        open={actDel.visible}
        footer={null}
        closable={false}
        onCancel={() => setActDel((prev) => ({ ...prev, visible: false }))}
      >
        <div className="text-center p-3 flex flex-col items-center gap-2">
          <h2 className=" font-bold">Delete Category</h2>

          <div className="border border-slate-300 w-full">
            <p className="m-0 p-4">
              <b style={{ color: colorTheme }}>{actDel.name}</b>

              <br />
              <span className="text-[#475569]">{actDel.desc}</span>
            </p>
          </div>

          <div className="flex flex-col gap-3 mt-4">
            <Button
              title="DELETE"
              type="primary"
              // onClick={() => handleDelete()}
              style={{
                backgroundColor: "#EB5757",
                borderRadius: 0,
              }}
            />

            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setActDel((prev) => ({ ...prev, visible: false }))}
              style={{
                backgroundColor: "white",
                color: colorTheme,
                border: "none",
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        open={action.add || action.edit || action.detail}
        footer={null}
        closable={false}
        onCancel={() => setAction((prev) => ({ ...prev, add: false }))}
      >
        <div className="text-center">
          <h2 className=" font-bold mb-8">
            {action.add ? "Add" : "Edit"} Category
          </h2>

          <Form
            layout="vertical"
            initialValues={
              action.add
                ? {}
                : {
                    category_name: action.name,
                    description: action.desc,
                  }
            }
          >
            <InputText
              required={false}
              name="category_name"
              label="Category Name"
              readOnly={action.detail}
            />

            <Form.Item name="description" label="Description:">
              <Input.TextArea rows={4} readOnly={action.detail} />
            </Form.Item>

            <div className="flex flex-col items-center gap-3 mt-4">
              <Button
                title="Save"
                type="primary"
                // onClick={() => handleDelete()}
                style={{
                  backgroundColor: colorTheme,
                  borderRadius: 0,
                }}
              />

              <Button
                title="Cancel"
                type="outline-secondary"
                onClick={() =>
                  setAction((prev) => ({
                    ...prev,
                    add: false,
                    edit: false,
                    detail: false,
                  }))
                }
                style={{
                  backgroundColor: "white",
                  color: colorTheme,
                  border: "none",
                }}
              />
            </div>
          </Form>
        </div>
      </Modal>
    </OrganismLayout>
  );
};

export default Category;
